import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

export const renderMarkdown = (text, options = {}) => {
  if (typeof text === "string") {
    return (
      <ReactMarkdown
        components={{ ...(!options?.paragraphs && { p: React.Fragment }) }}
        remarkPlugins={[remarkGfm]}
      >
        {text.replaceAll("'", "’")}
      </ReactMarkdown>
    );
  }

  return text;
};
