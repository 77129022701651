import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { ASSETS_CDN_HOST, BREAKPOINTS, MESSAGES } from "@/constants";

import styles from "./ResponsiveImage.module.scss";

const formats = [
  {
    filenameExtension: "webp",
    mediaType: "image/webp",
  },
  {
    filenameExtension: "jpg",
    mediaType: "image/jpeg",
  },
];

/**
 * https://www.contentful.com/developers/docs/references/images-api/
 */
const ResponsiveImage = ({
  altText,
  className,
  defaultImage,
  hgImage,
  layoutBlock,
  layoutInline,
  lazy,
  lgImage,
  maxWidth,
  mdImage,
  smImage,
  swapKey,
  title,
  xlImage,
  xsImage,
}) => {
  const [isLoading, setIsLoading] = useState(!!swapKey);
  const [imageUrlsByBreakpointLabel, setImageUrlsByBreakpointLabel] = useState({
    [BREAKPOINTS.NONE.label]:
      defaultImage?.fields.file.url || `${ASSETS_CDN_HOST}/images/clear.png`,
    [BREAKPOINTS.XS.label]: xsImage?.fields.file.url,
    [BREAKPOINTS.SM.label]: smImage?.fields.file.url,
    [BREAKPOINTS.MD.label]: mdImage?.fields.file.url,
    [BREAKPOINTS.LG.label]: lgImage?.fields.file.url,
    [BREAKPOINTS.XL.label]: xlImage?.fields.file.url,
    [BREAKPOINTS.HG.label]: hgImage?.fields.file.url,
  });

  const defaultImageUrl = imageUrlsByBreakpointLabel[BREAKPOINTS.NONE.label];

  const imageUrlForBreakpointValue = (breakpointValue) => {
    let imageUrl = defaultImageUrl;

    BREAKPOINTS.default.some((breakpoint) => {
      if (
        breakpoint.value <= breakpointValue &&
        imageUrlsByBreakpointLabel[breakpoint.label]
      ) {
        imageUrl = imageUrlsByBreakpointLabel[breakpoint.label];

        return true;
      }
    });

    return imageUrl;
  };

  /**
   * If no swap key is passed, show the default images.
   * Otherwise, if window.eqxSwaps[swapKey] exists when this component loads, show them;
   * Otherwise, wait a short bit to see if they get set (in which case, show them);
   * Otherwise, show the default images.
   *
   * window.eqxSwaps[swapKey] should look like:
    window.eqxSwaps = window.eqxSwaps || {};
    window.eqxSwaps["web3049-homepage-pagehero-images"] = {
      none: "https://images.ctfassets.net/drib7o8rcbyf/6NxESixHisIb2jNrKPlYO/9092dd5139faad1b30aec5327dae3808/red.png",
      xs: "https://images.ctfassets.net/drib7o8rcbyf/HkPh8TBTpML1jmyjbXXCy/e19722b1ce5ef27a22ac667dbfdcfb97/orange.png",
      sm: "https://images.ctfassets.net/drib7o8rcbyf/3v641DMgODIAEHVAYE3x1y/74f33740f930769d90b06823fb9385de/yellow.png",
      md: "https://images.ctfassets.net/drib7o8rcbyf/71W0cDPpmmWAHPvkKWCTb0/f36bb7dd8a7d105dfb1f326585399772/green.png",
      lg: "https://images.ctfassets.net/drib7o8rcbyf/4SYUBEQPruxWbxrAt6gklN/56a94bf3ba9bd5d1e53e031dad54633b/blue.png",
      xl: "https://images.ctfassets.net/drib7o8rcbyf/3PK14S1WcxBaeYEA1yhqOl/f6ba056eb8879d6366cf43a815c6f57b/indigo.png",
      hg: "https://images.ctfassets.net/drib7o8rcbyf/3NCCMg40RBfMmf2eq3e1SC/a51a2bc2800a44d41dee66cebd80247e/violet.png"
    };
  */
  useEffect(() => {
    const applyImageSwaps = (imageSwaps) => {
      const imageSwapsByBreakpointLabel = {};

      BREAKPOINTS.default.forEach((breakpoint) => {
        imageSwapsByBreakpointLabel[breakpoint.label] =
          imageSwaps[breakpoint.label];
      });

      setImageUrlsByBreakpointLabel(imageSwapsByBreakpointLabel);
      setIsLoading(false);
    };

    window.eqxSwaps = window.eqxSwaps || {};

    const imageSwaps = window.eqxSwaps[swapKey];

    let isMounted = true;
    let timeoutId;

    if (!swapKey) {
      setIsLoading(false);
    } else if (imageSwaps) {
      applyImageSwaps(imageSwaps);
    } else {
      Object.defineProperties(window.eqxSwaps, {
        ["_" + swapKey]: {
          configurable: true,
          value: undefined,
          writable: true,
        },
        [swapKey]: {
          configurable: true,
          get() {
            return this["_" + swapKey];
          },
          set(value) {
            this["_" + swapKey] = value;

            if (isMounted) {
              clearTimeout(timeoutId);
              applyImageSwaps(this["_" + swapKey]);
            }
          },
        },
      });

      timeoutId = setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }

    return () => {
      clearTimeout(timeoutId);
      isMounted = false;
    };
  }, [swapKey]);

  return (
    <picture className={classNames(styles.responsiveImage, className)}>
      {!isLoading &&
        BREAKPOINTS.default
          .filter((breakpoint) => breakpoint.label !== BREAKPOINTS.NONE.label)
          .map((breakpoint) => {
            let url = imageUrlForBreakpointValue(breakpoint.value);

            if (url.indexOf("//") === 0) {
              url = "https:" + url;
            }

            return formats.map(({ filenameExtension, mediaType }) => {
              const progressiveFlag =
                filenameExtension === "jpg" ? "&fl=progressive" : "";

              return (
                <source
                  key={`${breakpoint.label}-${filenameExtension}`}
                  media={`(min-width: ${breakpoint.value}px)`}
                  srcSet={[
                    `${url}?fm=${filenameExtension}${progressiveFlag}&fit=fill&w=${Math.min(
                      breakpoint.value,
                      maxWidth
                    )} 1x`,
                    `${url}?fm=${filenameExtension}${progressiveFlag}&fit=fill&w=${Math.min(
                      breakpoint.value * 2,
                      maxWidth
                    )} 2x`,
                    `${url}?fm=${filenameExtension}${progressiveFlag}&fit=fill&w=${Math.min(
                      breakpoint.value * 3,
                      maxWidth
                    )} 3x`,
                  ].join(", ")}
                  type={mediaType}
                />
              );
            });
          })}

      {!isLoading && defaultImageUrl && (
        <img
          alt={altText}
          loading={lazy ? "lazy" : "eager"}
          src={`${
            (defaultImageUrl.indexOf("//") === 0 ? "https:" : "") +
            defaultImageUrl
          }?fm=jpg&fl=progressive&fit=fill&w=640`}
          style={{
            objectPosition: `${layoutInline}% ${layoutBlock}%`,
          }}
          title={title}
        />
      )}
    </picture>
  );
};

ResponsiveImage.defaultProps = {
  altText: MESSAGES.DEFAULT_ALT_TEXT,
  layoutBlock: 50,
  layoutInline: 50,
  lazy: true,
  maxWidth: 4000,
  title: MESSAGES.TAGLINE,
};

ResponsiveImage.propTypes = {
  altText: PropTypes.string,
  className: PropTypes.string,
  defaultImage: PropTypes.object,
  hgImage: PropTypes.object,
  layoutBlock: PropTypes.number,
  layoutInline: PropTypes.number,
  lazy: PropTypes.bool,
  lgImage: PropTypes.object,
  maxWidth: PropTypes.number,
  mdImage: PropTypes.object,
  smImage: PropTypes.object,
  swapKey: PropTypes.string,
  title: PropTypes.string,
  xlImage: PropTypes.object,
  xsImage: PropTypes.object,
};

export default ResponsiveImage;
