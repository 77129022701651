import PropTypes from "prop-types";
import React from "react";

import ArrowDown from "@/assets/icons/brand-hero-arrow-down.svg";
import ResponsiveMedia from "@/components/ResponsiveMedia";
import useAcdl from "@/hooks/useAcdl";
import { renderMarkdown } from "@/utils/helpers/markdown";

import styles from "./PageHero.module.scss";

const PageHero = ({ data }) => {
  const {
    alignItems = "start",
    backgroundMedia,
    cta,
    hasBackgroundShade = false,
    headline,
    headlinePlacement = "overlay",
    headlineSize = "xl",
    isCompact = false,
    layoutBlock = "normal",
    layoutInline = "normal",
    layoutSpacing = "center",
    showNextSectionButton = false,
    subheading,
    textShadow = "none",
    theme = "dark",
  } = data;

  const { acdlPush } = useAcdl();

  const scrollToNextSection = () => {
    const globalHeader = document?.querySelector('[data-is="GlobalHeader"]');
    const nextSection = document?.querySelector('[data-is="PageHero"] + *');

    if (nextSection) {
      window.scrollTo({
        behavior: "smooth",
        left: 0,
        top: nextSection.offsetTop - globalHeader.offsetHeight,
      });

      acdlPush({
        click: {
          destination: "NA",
          location: "Page Hero",
          name: "Down Arrow",
        },
        event: "mod-click",
        module: {
          name: "ArrowTapped",
          position: "1:1",
          type: "icon",
        },
      });
    }
  };

  return (
    <header
      className={styles.pageHero}
      data-background-shade={hasBackgroundShade}
      data-compact={isCompact}
      data-headline-placement={headlinePlacement}
      data-headline-size={headlineSize}
      data-is="PageHero"
      data-layout-block={layoutBlock}
      data-layout-inline={layoutInline}
      data-layout-spacing={layoutSpacing}
      data-text-align={alignItems}
      data-text-shadow={textShadow}
      data-theme={theme}
    >
      <hgroup className={styles.content}>
        <span className={styles.contentBlock}>
          {headline && (
            <h1 className={styles.headline}>{renderMarkdown(headline)}</h1>
          )}

          {subheading && (
            <p className={styles.subheading}>{renderMarkdown(subheading)}</p>
          )}

          {cta && (
            <a
              className={styles.cta}
              href={cta?.fields?.url}
              title={cta?.fields?.title}
            >
              {cta?.fields?.text}
            </a>
          )}

          {showNextSectionButton && (
            <button
              aria-label="Scroll to next section"
              className={styles.scrollToNextSection}
              name="PageHero - Down Arrow"
              onClick={() => scrollToNextSection()}
            >
              <ArrowDown />
            </button>
          )}
        </span>
      </hgroup>

      {backgroundMedia && (
        <ResponsiveMedia
          className={styles.backgroundMedia}
          lazy={false}
          media={backgroundMedia}
        />
      )}
    </header>
  );
};

PageHero.propTypes = {
  data: PropTypes.object,
};

export default PageHero;
